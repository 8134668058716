<template>
  <div class="sidebar">
    <div class="d-flex flex-column p-3 bg-white" style="width: 240px;height: 100%;">
      <div class="d-flex align-items-center pb-3 mb-3  ">
        <div class="container-fluid d-flex justify-content-center">
          <router-link class="navbar-brand" to="/" style="background-color: #ffffff;">
            <img src="../assets/lighetenLogo.png" height="50" alt="homepage" />
          </router-link>
        </div>
      </div>
      <div class="dropdown gap-2 d-flex m-2">
        <div
class=" d-flex align-items-center link-dark text-decoration-none dropdown-toggle " id="dropdownUser2"
          @click.stop="showis = !showis">
          <strong>{{ userId }}</strong>
        </div>
        <ul v-if="showis" class="dropdown-menu text-small shadow show">
          <!-- <hr class="dropdown-divider"> -->
          <li>
            <router-link class="dropdown-item" to="/login" @click="useLogin.logout">
              <span>Sign out</span>
            </router-link>
          </li>
        </ul>
        <div class="align-self-end ">

          <div>{{ tokenRemain }}</div>
          <button class="badge bg-secondary" @click="loginRefresh(userId)">시간연장</button>

        </div>
      </div>
      <!-- <nav class="sidebar-nav">
        <div class="p-3 text-center">
          <input type="date" v-model="today" class="form-control mb-2">
          <div class="sidebar-item mb-3" @click="useUser.logout">
            <router-link class="sidebar-link" to="/">
              <i class="fas fa-sign-out-alt mr-2"></i>
              <span>Logout</span>
            </router-link>
          </div>
          <div class="mb-3">
            ADMIN
          </div>
          <div class="mb-3">
            50:00
          </div>
        </div>
      </nav> -->
      <input type="date" v-model="today" class="form-control mb-1">
      <!-- <div class="row"> -->
      <div>
        <p class="btn btn-primary float-start" @click="plusDate(-1)">Prev</p>
        <p class="btn btn-primary float-right" @click="plusDate(1)">Next</p>
      </div>
      <hr>

      <nav class="sidebar-nav nav-pills flex-column mb-3">
        <ul class="nav nav-pills flex-column mb-auto">
          <h4>제품생산</h4>
          <li class="sidebar-item mb-1">
            <router-link class="sidebar-link" to="/FineDrop2">
              <i></i>
              <span>FineDrop2</span>
            </router-link>
          </li>
          <li class="sidebar-item mb-1">
            <router-link class="sidebar-link" :to="{ name: 'FinewayPro' }">
              <i></i>
              <span>FineWay</span>
            </router-link>
          </li>
          <li v-if="true " class="sidebar-item mb-1">
            <router-link class="sidebar-link" :to="{ name: 'productionInventory' }">
              <i></i>
              <span>생산재고 관리</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <nav class="sidebar-nav nav-pills flex-column mb-auto">
        <h4>입/출고 관리</h4>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="sidebar-item mb-1">
            <router-link class="sidebar-link" to="/storage/FineDrop">
              <i></i>
              <span>FineDrop2</span>
            </router-link>
          </li>
          <li class="sidebar-item mb-1">
            <router-link class="sidebar-link" to="/storage/FineWay">
              <i></i>
              <span>FineWay</span>
            </router-link>
          </li>
          <li class="sidebar-item mb-1">
            <router-link class="sidebar-link" to="/storage/Other">
              <i></i>
              <span>Other</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <footer class="border-top mt-auto ">
        <div class="text-end fs-6">Published By 김현석</div>
      </footer>
    </div>
  </div>
</template>
<script setup>
  import { ref, watch, onMounted, onUnmounted } from 'vue';
  import { useUserStore } from "@/stores/user.js";
  import { useLoginStore } from "@/stores/login.js";
  import dayjs from 'dayjs';

  const useLogin = useLoginStore();
  const useUser = useUserStore();

  const showis = ref(false);
  const tokenRemain = ref('');
  const today = ref(dayjs().format('YYYY-MM-DD'));
  // useUser.setdate(today.value)

  const hideDropdown = () => {
    showis.value = false;
  };
  const userId = ref('');
  watch(today, () => useUser.setdate(today));
  let tokenExpireInterval;

  function tokenTimer() {
    const remainTime = tokenRemain.value.split(" ");
    const minute = remainTime[0].split("분");
    const second = remainTime[1].split("초");

    if (second[0] == 0) {
      if (minute[0] == 0) {
      // 로그아웃
      } else {
        const newMinute = String(minute[0] - 1).padStart(2, '0');
        tokenRemain.value = `${newMinute}분 59초`;
      }
    } else {
      const newSecond = String(second[0] - 1).padStart(2, '0');
      tokenRemain.value = `${minute[0]}분 ${newSecond}초`;
    }
    if (tokenRemain.value == "00분 00초") {
      // 로그아웃
      useLogin.logout();
    }

  }
  // function convertToDayjs(timeString){
  //   const [minutes, seconds] = timeString.split(" ").map((time) => time.replace(/[^\d]/g, ""));
  //   console.log(minutes, seconds);
  //   return dayjs().minute(minutes).second(seconds);
  // }
  async function loginRefresh(id) {
    await useLogin.loginRefresh(id);
    tokenRemain.value = await useLogin.isLogined();
    // console.l
    // login token expire timer
    try {
      clearInterval(tokenExpireInterval);
    } catch (error) {
      console.log("No Interval to clear");
    }
    tokenExpireInterval = setInterval(tokenTimer, 1000);


  }

  function plusDate(number) {
    today.value = dayjs(today.value).add(number, 'day').format('YYYY-MM-DD');
    useUser.setdate(today);
  }

  onMounted(async () => {
    userId.value = localStorage.getItem('userId');

    tokenRemain.value = await useLogin.isLogined();
    // console.log("원본데이터", limit.value);
    // limit.value = convertToDayjs(limit.value);

    // login token expire timer
    try {
      clearInterval(tokenExpireInterval);
    } catch (error) {
      console.log("No Interval to clear");
    }
    tokenExpireInterval = setInterval(tokenTimer, 1000);
    document.addEventListener('click', hideDropdown);
    useUser.setdate(today.value);
  });
  onUnmounted(() => {
    clearInterval(tokenExpireInterval);
    document.removeEventListener('click', hideDropdown);
  });


</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(0px, -34.6667px, 0px);
  padding: 10px 0px;

}

a,
li {
  text-align: center;
}

.top-navbar {
  padding: 20px 5px;
}

.router-link-active {
  /* background-color: #dee2e6; */

}

.router-link-active>span {
  color: #0d6efd;
  text-transform: uppercase;

}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: calc(max(100%, 100vh));
  width: 240px;
  top: 0px;
  background: #fff;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  /* background-color: #678FFF; */
}

.bg-secondary {
  background-color: #0d6efd !important;
  color: #FFFFFF !important;
}

span {
  color: #313131;
  font-weight: 400;
}


.sidebar-link {

  font-weight: 300;
  color: #54667a;
  text-decoration: none;
  padding: 15px;
}

.sidebar-nav ul li {
  list-style: none;
}

ul {
  margin: 0px;
  padding: 0px;
}


.navbar-header {
  padding: 0px 5px 0px 15px;
}

.sidebar-nav ul .sidebar-item {
  width: 100%;
}

.sidebar-nav ul .sidebar-item .sidebar-link .active {
  font-weight: 500;
  border-left: 3px solid #41b3f9;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 15px;
  border-left: 3px solid transparent;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 20px;
  font-weight: 300;
}

.sidebar-nav ul .sidebar-item .sidebar-link i {
  font-style: normal;
  width: 35px;
  line-height: 20px;
  font-size: 23px;
  color: #54667a;
  text-align: center;
  font-size: 14px;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover {
  background: rgba(0, 0, 0, 0.07);
}

.sidebar-nav ul .sidebar-item .sidebar-link .active {
  font-weight: 500;
  border-left: 3px solid #41b3f9;
  padding-left: 15px;
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link {
  opacity: 1;
}


.sidebar-nav ul .sidebar-item .sidebar-item .active .sidebar-link {
  opacity: 1;
}

.sidebar-nav ul .sidebar-item .sidebar-item .sidebar-link {}


.sidebar-nav ul .sidebar-item .sidebar-item .sidebar-link i {}

.sidebar-nav ul .nav-small-cap {
  font-size: 12px;
  padding: 15px 35px 15px 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 30px;
  margin-top: 10px;
  color: #fff;
  opacity: 0.3;
  text-transform: uppercase;
}

.sidebar-nav ul {
  line-height: 30px;
  margin: 0 5px;
}


.sidebar-nav ul {
  margin: 0px;
  padding: 0px;
}

.sidebar-nav ul li {
  list-style: none;
}
</style>
