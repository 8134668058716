<template>
  <div class="row">
    <div class="col-12 justify-content-end">
      <div class="d-flex justify-content-between ju gap-2">
        <div class="fs-6">{{ userInfo.userName }}</div>
        <button class=" btn badge text-bg-primary " @click="loginRefresh(userInfo.userName)">갱신</button>
        <div class="fs-6 font-weight-light bg-grey-lighten-5 ">로그인 만료 시간 : {{ userInfo.remainTime }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useLoginStore } from "@/stores/login";
import { onBeforeMount, ref } from "vue";

const useLogin = useLoginStore();
const userInfo = ref({
  userName: "",
  remainTime: "",
});

async function loginRefresh(id) {
  await useLogin.loginRefresh(id);
  userInfo.value.remainTime = await useLogin.isLogined();

}
onBeforeMount(() => {
  const token = useLogin.token;
  const decoded = jwtDecode(token);
  userInfo.value.userName = decoded.id;
  console.log('token :>> ', token);
  const config = {
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url: "https://www.finefactory.xyz/api/v1/auth/tokenRemain",
  };
  axios(config).then(async (res) => {
    console.log(res.data);
    // res.data.remainTimeString = 10분 37초
    userInfo.value.remainTime = await useLogin.isLogined();
    setInterval(() => {
      // minus 1 second
      const remainTime = userInfo.value.remainTime?.split(" ");
      const minute = remainTime[0].split("분");
      const second = remainTime[1].split("초");

      if (second[0] == 0) {
        if (minute[0] == 0) {
          // 로그아웃
        } else {
          userInfo.value.remainTime = `${minute[0] - 1}분 59초`;
        }
      } else {
        userInfo.value.remainTime = `${minute[0]}분 ${second[0] - 1}초`;
      }
      if (userInfo.value.remainTime == "0분 0초") {
        // 로그아웃
        useLogin.logout();
      }
    }, 1000);
  });
});
</script>
