// import VueCookies from "vue-cookies";
import axios from "axios";
import router from "@/router";

// const cookie = VueCookies;
//jwt 를 위한 토큰 저장 구간
export const axiosJWT = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
axiosJWT.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("usertoken");
    // cookie.get("token");
    // server에서 JWT를 가져옵니다.
    if (token == null) {
      router.push("/login");
      return Promise.reject(new Error("Token is missing")); // Promise.reject를 사용하여 요청을 취소합니다.
    }
    config.headers.Authorization = `Bearer ${token}`; // JWT를 인증 헤더에 추가합니다.
    return config;
  },
  (error) => {
    return Promise.reject(error); // 요청이 실패한 경우 에러 처리를 위해 Promise.reject를 반환합니다.
  }
);
axiosJWT.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("usertoken");
      // cookie.remove("token");
      router.push("/login"); // 401 상태 코드이면 로그인 페이지로 이동
    }
    return Promise.reject(error);
  }
);
