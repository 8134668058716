<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-primary">
      <div class="col-12 d-flex px-2">
        <router-link class="navbar-brand mx-auto text-center align-content-center" to="/mobile">
          <img src="../assets/lighten_logo_white.svg" height="30"></router-link>
        <button class="navbar-toggler" type="button" @click="toggleNavbar" :aria-expanded="navbarOpen">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div :style="{ maxHeight: `${navbarHeight}px` }" class="navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto px-2">
          <li class="nav-item">
            <router-link class="nav-link" to="/mobile/ioLog" @click="toggleNavbar">이력조회</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/mobile/ioConfirm" @click="toggleNavbar">입/출고 기록</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/mobile/regBarcode" @click="toggleNavbar">바코드등록</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/" @click="useLogin.logout">Logout</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container mt-1 mb-2">
      <ProfileCard></ProfileCard>
    </div>
    <router-view></router-view>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import { useLoginStore } from "@/stores/login";
  import ProfileCard from "@/components/mobile/ProfileCard.vue";
  const useLogin = useLoginStore();
  // get token from cookie

  // parse jwt token

  let navbarOpen = ref(false);
  let navbarHeight = ref(0);

  function toggleNavbar() {
    const navbarContent = document.querySelector("#navbarNav ul");
    if (!navbarOpen.value) {
      navbarHeight.value = navbarContent.scrollHeight;
    } else {
      navbarHeight.value = 0;
    }
    navbarOpen.value = !navbarOpen.value;
  }
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script");

/* Customize the appearance of the header and logo */
.b-navbar {
  background-color: #678fff;
}

.b-navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.navbar-collapse {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.bg-primary {
  background-color: #678fff !important
}


.table>:not(caption)>*>* {
  padding: 0.2rem 0.2rem;
}
</style>
