<template>
	<div class="app">
		<router-view />
	</div>
</template>
<script setup>
</script>

<style>
	* {
		font-family: NotoSans;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.app {
		height: 100vh;
		font-family: "Noto Sans", sans-serif;
		color: #2c3e50;
	}

	/* .MainView {
	height: calc(max(100%, 100vh));
	width: 100%
} */

	/* Firefox */
	* {
		scrollbar-width: 10px;
		scrollbar-color: #454545 #edf1f5;
	}

	/* Chrome, Edge, and Safari */
	*::-webkit-scrollbar {
		width: 16px;
	}

	*::-webkit-scrollbar-track {}

	*::-webkit-scrollbar-thumb {
		background-color: #454545;
		border-radius: 10px;
		border: 1px solid #ffffff;
	}

	html {
		overflow: auto !important;
		/* scrollbar-width: none;
	-ms-overflow-style: none; */
	}

	.home {
		margin-left: 240px;
		height: calc(max(100%, 100vh));
		width: calc(100% - 240px);
		max-width: calc(100% - 240px);
		background-color: #edf1f5;
		box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	}

	.homeview {
		height: calc(max(100%, 100vh));
	}

	/* html::-webkit-scrollbar {
	width: 0;
	height: 0;
} */
</style>

