import { createApp } from "vue";
import { createPinia } from "pinia";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router/index";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { VDataTable } from "vuetify/labs/VDataTable";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "v-calendar/style.css";
import VCalendar from "v-calendar";
import { Calendar, DatePicker } from "v-calendar";
const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
  components: { VDataTable },
});
const app = createApp(App);
app.component("calendar-vue", Calendar);
app.component("datePicker", DatePicker);
app.use(VueCookies);
app.use(VCalendar);
app.use(router);
app.use(createPinia());
app.use(vuetify);
app.mount("#app");
