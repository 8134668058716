import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import router from "@/router";
import { server } from "@/plugins/server.js";
import { axiosJWT } from "@/plugins/axiosJWT.js";

export const useLoginStore = defineStore("login", () => {
	const logindata = {
		id: "Demo",
		password: "",
	};
	const token = localStorage.getItem("usertoken");
	const memberList = ref([]);
	async function getMemberList() {
		const config = {
			method: "get",
			url: `${server}/auth/memberList`,
		};
		memberList.value = await axiosJWT(config).then((res) => {
			return res.data.memberList;
		});
	}
	//로그인 api

	function Mobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	}

	async function loginUser(payload) {
		try {
			logindata.id = payload.id;
			logindata.password = payload.password;
			const config = {
				method: "post",
				url: `${server}/auth/login`,
				data: logindata,
			};
			await axios(config).then((res) => {
				// cookie.set("token", res.data?.token);
				localStorage.setItem("usertoken", res.data?.token);
				if (res.data.message == "로그인 성공") {
					if (Mobile()) {
						router.push("/mobile");
					} else {
						router.push("/");
					}
				}
				this.islogin = true;
				localStorage.setItem("userId", logindata.id);
				// useCookies.set('token', res.data?.token, { expires: '1h' })
			});
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
	async function register(id, password, name) {
		const configData = {
			id: id,
			password: password,
			name: name,
		};
		const config = {
			method: "post",
			url: `${server}/auth/register`,
			data: configData,
		};
		await axios(config);
	}

	// TODO Token 확인loginUser
	async function isLogined() {
		const config = {
			method: "get",
			url: `${server}/auth/tokenRemain`,
		};
		await axiosJWT(config).then((res) => {
			this.islogin = res.data.remainTimeString;
		});
		return this.islogin;
	}

	async function loginRefresh(userId) {
		const data = {
			id: userId,
		};
		const config = {
			method: "post",
			url: `${server}/auth/refreshToken`,
			data: data,
		};
		await axiosJWT(config).then((res) => {
			// cookie.remove('token');
			localStorage.setItem("usertoken", res.data?.token);
			// cookie.set("token", res.data.token, "1h");
			// console.log(res.data.token);
		});
	}
	function logout() {
		localStorage.removeItem("usertoken");
		// cookie.remove("token");
	}
	return {
		//state
    token,
		memberList,
		//getter 
    register,
    logout,
    loginUser,
    isLogined,
    loginRefresh,
    getMemberList,
  };
});
