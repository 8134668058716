import { defineStore } from "pinia";
import { ref } from "vue";
import qs from "qs";
import { axiosJWT } from "@/plugins/axiosJWT.js";
import { server } from "@/plugins/server.js";

export const useUserStore = defineStore("user", () => {
  let date = "";
  const deviceData = ref(new Map());

  async function enableDeviceSetMode(payload) {
    const configData = {
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
    };
    const config = {
      method: "put",
      url: `${server}/device/enableDeviceSetMode`,
      data: configData,
    };
    return axiosJWT(config).then((res) => {
      return res.message == "장치 설정 모드 활성화 성공";
    });
  }
  function changeLots(payload) {
    const configData = {
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
      lotsArray: payload.lotsArray,
      regDate: payload.device_regdate,
      completeDate: payload.device_complete_date,
    };
    const config = {
      method: "put",
      url: `${server}/device/changeLots`,
      data: configData,
    };
    return axiosJWT(config).then((res) => {
      return res.message;
    });
  }
  async function factorySetting(payload) {
    const configData = qs.stringify({
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
    });
    const config = {
      method: "get",
      url: `${server}/device/factorySetting?${configData}`,
    };
    return axiosJWT(config).then((res) => {
      return res.message;
    });
  }
  // devicelist 전체 갱신 api
  async function getDeviceList(
    devicetype,
    regdate,
    devicestate,
    flag,
    gwSerial
  ) {
    let config;
    const configData = qs.stringify({
      regDate: regdate,
      deviceType: devicetype,
      deviceState: devicestate,
      deviceGw: gwSerial,
    });
    config = {
      method: "get",
      url: `${server}/device/deviceInfo?${configData}`,
    };

    const data = await axiosJWT(config).then((res) => {
      // 기존의 데이터 초기화
      if (flag == true) deviceData.value.clear();
      // 순회하며 데이터 기입
      for (const item in res.data?.deviceList) {
        deviceData.value.set(
          res.data?.deviceList[item].device_serial,
          res.data?.deviceList[item]
        );
      }
      return res.data.deviceList;
    });
    return data;
  }
  async function getDevice(payload) {
    const configData = qs.stringify({
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
    });
    const config = {
      method: "get",
      url: `${server}/device/deviceInfo?${configData}`,
    };
    const data = await axiosJWT(config);
    return data.data.deviceList[0];
  }
  // 서버에 device 추가
  async function addDeviceList(payload) {
    const configData = {
      device_type: payload.deviceType,
      device_serial: payload.deviceSerial,
      device_serial_2: payload.deviceSerial_2,
    };
    const config = {
      method: "post",
      url: `${server}/device/fineway/create`,
      data: configData,
    };
    await axiosJWT(config).then((res) => {
      console.log("Create : ", res);
      deviceData.value.set(payload.deviceSerial, configData);
    });
  }
  // server의 데이터 제거 후 pinia의 data 를 제거
  async function deleteDevice(type, serial) {
    const configData = {
      deviceType: type,
      deviceSerial: serial,
    };
    const config = {
      method: "delete",
      url: `${server}/device/deleteDevice`,
      data: configData,
    };
    await axiosJWT(config).then((res) => {
      deviceData.value.delete(serial);
      console.log("Delete : ", res.message);
    });
  }

  // denied로 변환하는 코드
  async function deniedDevice(type, serial) {
    const configData = {
      deviceType: type,
      deviceSerial: serial,
      deviceState: "denied",
    };
    const config = {
      method: "put",
      url: `${server}/device/updateDeviceState`,
      data: configData,
    };
    await axiosJWT(config).then((res) => {
      deviceData.value.delete(serial);
      console.log("Denied : ", res.message);
    });
  }
  //제품생산확인 내용을 이월하는 코드(내일로 미룸 )
  async function deviceNextDay(type, serial) {
    const configData = {
      deviceType: type,
      deviceSerial: serial,
    };
    const config = {
      method: "put",
      url: `${server}/device/updateDeviceNextDay`,
      data: configData,
    };
    await axiosJWT(config).then(() => {
      deviceData.value.delete(serial);
    });
  }
  async function produceDevice(Type, Serial) {
    const configData = {
      deviceType: Type,
      deviceSerial: Serial,
    };
    const config = {
      method: "put",
      data: configData,
      url: `${server}/device/produceDevice`,
    };
    await axiosJWT(config);
  }
  async function updateDeviceUdi(Type, Serial, Udi) {
    const configData = {
      deviceType: Type,
      deviceSerial: Serial,
      deviceUdi: Udi,
    };
    const config = {
      method: "put",
      data: configData,
      url: `${server}/device/updateUdi`,
    };
    await axiosJWT(config);
  }
  // 날 갱신 api
  function setdate(today) {
    this.date = today;
  }
  async function getGWList(Type) {
    const config = {
      method: "get",
      url: `${server}/device/getGwList?deviceType=${Type}`,
    };
    const returndata = await axiosJWT(config);
    return returndata.data.gwList;
  }
  async function completeDevice(payload, flag) {
    const configData = {
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
      factoryCheck: payload.factoryCheck,
      productCheck: payload.productCheck,
      completeDate: payload.date,
    };

    console.log("저장", configData);
    const config = {
      method: "put",
      data: configData,
      url: `${server}/device/completeDevice`,
    };
    await axiosJWT(config);
    if (flag == true) {
      ioCreateInit(configData, payload.name, payload.date);
    }
  }
  function ioCreateInit(payload, Name, Date) {
    const configData = {
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
      ioMember: Name,
      ioType: "입고",
      ioPlace: "GMP",
      ioDate: Date,
    };
    console.log(configData);
    const config = {
      method: "post",
      url: `${server}/storage/io/create`,
      data: configData,
    };
    axiosJWT(config);
  }
  async function updateItem(payload) {
    const updateData = {
      item_type: payload?.item_type,
      item_level: payload?.item_level,
      item_lots: payload?.item_lots,
      item_factory_checks: payload?.item_factory_checks,
      item_product_checks: payload?.item_product_checks,
      item_udi: payload?.item_udi,
    };
    console.log(updateData);
    const config = {
      method: "put",
      data: updateData,
      url: `${server}/item/update`,
    };
    await axiosJWT(config);
  }
  async function updateDeviceRegDate(payload) {
    const configData = {
      deviceType: payload.deviceType,
      deviceSerial: payload.deviceSerial,
      deviceRegDate: payload.deviceRegDate,
    };
    console.log(configData);
    const config = {
      method: "put",
      data: configData,
      url: `${server}/device/updateDeviceRegDate`,
    };
    await axiosJWT(config);
  }
  return {
    deviceData,
    date,
    setdate,
    updateDeviceRegDate,
    updateItem,
    completeDevice,
    updateDeviceUdi,
    produceDevice,
    addDeviceList,
    getDeviceList,
    getDevice,
    deviceNextDay,
    deleteDevice,
    deniedDevice,
    getGWList,
    enableDeviceSetMode,
    factorySetting,
    changeLots,
  };
});
