<template>
  <div
    class="p-4 bg-gray-100 min-h-screen"
  >
    <div
      class="bg-white p-4 rounded-lg shadow"
    >
      <h2
        class="text-2xl font-bold mb-4"
      >
        재고 리스트
      </h2>
      <div class="text-2xl text-end">
        생산 가능 수량 :
        {{ deviceProducible }}
        <br />
        전체 주문 수량 :
        {{ deviceProducible }}
      </div>
      <div class="overflow-x-auto">
        <div
          class="card m-4 overflow-auto"
          style="
            height: 75%;
            max-height: 75%;
          "
        >
          <div class="table-responsive">
            <v-data-table
              :headers="headers"
              :items="items"
              v-model="selectedDeviceSerialArray
                "
              item-value="device_serial"
              show-select
              multi-sort
            >
              <template
                v-slot:item.action="{ item }"
              >
                <button
                  class="btn btn-primary"
                  @click=""
                >
                  조회
                </button>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useLotStore } from '@/stores/lots.js';

  const useLots = useLotStore();
  const deviceProducible = ref(1); // produce 단계의 갯수 선언용
  const deviceType = 'FINEDROP-V02';
  const selectedDeviceSerialArray = ref(
    []
  );

  onMounted(async () => {
    deviceProducible.value =
      await useLots.deviceProducible(
        deviceType
      );
  });
  const headers = [
    { title: 'No.', value: 'no' },
    {
      title: '납품지',
      value: 'deliveryLocation',
    },
    {
      title: '생산 타입',
      value: 'productionType',
    },
    {
      title: '생산 단계',
      value: 'productionStage',
    },
    {
      title: '담당자',
      value: 'responsiblePerson',
    },
    {
      title: '필요 생산 수',
      value: 'productionQuantity',
      align: 'center',
    },
    {
      title: '현재 생산된 수',
      value:
        'currentProductionQuantity',
      align: 'center',
    },
    {
      title: '주문 일시',
      value: 'orderDate',
      align: 'center',
    },
    {
      title: '주문 마감일',
      value: 'orderDeadline',
    },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      key: 'action',
    },
  ];

  const items = ref([
    {
      no: 1,
      deliveryLocation: '서울',
      productionType: 'FINEDROP-V02',
      productionStage: '단계 1',
      responsiblePerson: '홍길동',
      productionQuantity: 100,
      currentProductionQuantity: 50,
      orderDate: '2023-08-20',
      orderDeadline: '2023-08-30',
      actions: '1',
    },
    {
      no: 2,
      deliveryLocation: '부산',
      productionType: 'FINEDROP-V02',
      productionStage: '단계 2',
      responsiblePerson: '김철수',
      productionQuantity: 200,
      currentProductionQuantity: 150,
      orderDate: '2023-08-21',
      orderDeadline: '2023-09-01',
    },
    // 추가 데이터...
  ]);
</script>

<style scoped>
  /* 추가적인 스타일링이 필요하다면 여기에 작성할 수 있습니다. */
</style>
