import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import register from "../views/RegisterView.vue";
import FineDrop2 from "../views/FineDrop2.vue";
import productionInventory from "@/components/productionInventory/DeviceCreateList.vue"
import FinewayPro from "../views/FinewayPro.vue";
import MobileView from "@/views/MobileView.vue";
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: register,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '',
        name: 'DashBoard',
        component: () =>
          import(
            '@/components/TheDashBoard.vue'
          ),
      },
      {
        path: '/FineDrop2',
        name: 'FineDrop2',
        component: FineDrop2,
        children: [
          {
            path: 'create',
            component: () =>
              import(
                '@/components/finedrop/DeviceCreateList.vue'
              ),
          },
          {
            path: 'lotList',
            component: () =>
              import(
                '@/components/lot/lotList.vue'
              ),
            props: {
              deviceType:
                'FINEDROP-V02',
            },
          },
          {
            path: 'device',
            component: () =>
              import(
                '@/components/finedrop/DeviceCheckProduct.vue'
              ),
          },
          {
            path: 'totalList',
            component: () =>
              import(
                '@/components/finedrop/DeviceTotalList.vue'
              ),
          },
        ],
      },
      {
        path: '/Fineway',
        name: 'FinewayPro',
        component: FinewayPro,
        children: [
          {
            path: 'create',
            component: () =>
              import(
                '@/components/fineway/DeviceCreateList.vue'
              ),
          },
          {
            path: 'lotList',
            component: () =>
              import(
                '@/components/lot/lotList.vue'
              ),
            props: {
              deviceType:
                'FINEWAYPRO-V01',
            },
          },
          {
            path: 'device',
            component: () =>
              import(
                '@/components/fineway/DeviceCheckProduct.vue'
              ),
          },
          {
            path: 'totalList',
            component: () =>
              import(
                '@/components/fineway/DeviceTotalList.vue'
              ),
          },
        ],
      },
      {
        path: '/productionInventory',
        name: 'productionInventory',
        component: productionInventory,
        children: [
          {
            path: 'create',
            component: () =>
              import(
                '@/components/fineway/DeviceCreateList.vue'
              ),
          },
          {
            path: 'lotList',
            component: () =>
              import(
                '@/components/lot/lotList.vue'
              ),
            props: {
              deviceType:
                'FINEWAYPRO-V01',
            },
          },
          {
            path: 'i9device',
            component: () =>
              import(
                '@/components/fineway/DeviceCheckProduct.vue'
              ),
          },
          {
            path: 'totalList',
            component: () =>
              import(
                '@/components/fineway/DeviceTotalList.vue'
              ),
          },
        ],
      },

      {
        path: '/storage',
        name: 'Storage',
        children: [
          {
            path: 'FineDrop',
            component: () =>
              import(
                '@/components/storage/TheFinedropStorage.vue'
              ),
          },
          {
            path: 'FineWay',
            component: () =>
              import(
                '@/components/storage/TheFinewayStorage.vue'
              ),
          },
          {
            path: 'Other',
            component: () =>
              import(
                '@/components/storage/TheOtherStorage.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: MobileView,
    children: [
      {
        path: '',
        name: 'Home',
        component: () =>
          import(
            '@/components/mobile/TheHome.vue'
          ),
      },
      {
        path: 'ioLog',
        name: 'IoLog',
        component: () =>
          import(
            '@/components/mobile/TheIoLog.vue'
          ),
      },
      {
        path: 'ioConfirm',
        name: 'IoConfirm',
        component: () =>
          import(
            '@/components/mobile/TheIoConfirm.vue'
          ),
      },
      {
        path: 'regBarcode',
        name: 'RegBarcode',
        component: () =>
          import(
            '@/components/mobile/TheRegBarcode.vue'
          ),
      },
    ],
  },
];

const router = createRouter({
  //rocess.env.BASE_URL
  history: createWebHistory("/"),
  routes,
});

export default router;
