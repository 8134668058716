<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-6">
        <div class="card px-5 py-5  justify-content-center" id="form1">
          <div class="h2 text-center mb-5">
            <router-link class="text-muted" to="/">
              <img class="img" src="../assets/fineFactroy_logo.png" />
            </router-link>
          </div>
          <form class="form-data">
            <div class="forms-inputs mb-4">
              <span>User ID</span>
              <input type="id" class="form-control" id="id" v-model="id" required>
            </div>
            <div class="forms-inputs mb-4">
              <span>Password</span>
              <input type="password" class="form-control" id="password" v-model="password" required>
            </div>
            <div class="mb-3"> <button class="w-100 btn btn-lg btn-primary" @click.prevent="login">Login</button></div>
            <div class="mt-5 mb-3 text-center">
              <router-link class="text-muted" to="register">회원가입</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import router from '@/router';
  import { onMounted } from 'vue';
  import { useLoginStore } from '@/stores/login.js';
  const uselogin = useLoginStore();
  let id;
  let password;

  async function login() {
    try {
      const payload = {
        id: id,
        password: password
      };
      uselogin.loginUser(payload);
    } catch (error) {
      console.error(error);
    }
  }


  function Mobile() {
    console.log('접속중인 기기 종류가 PC인지 : ', /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  onMounted(() => {
    // server에서 JWT를 가져옵니다.
    if (uselogin.isLogined() != false) {
      if (Mobile()) {
        router.push('/mobile');
      } else {
        router.push('/');
      }
    }
  });
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script');

.container {
  position: relative;
  top: 20%;
}

@media (max-width: 1200px) {
  .img {
    height: 100px;
  }
}

@media (max-width: 700px) {
  .img {
    height: 50px;
  }
}

.h2 {
  font-family: 'Kaushan Script', cursive;
  font-size: 3.5rem;
  font-weight: bold;
  color: #678FFF;
  font-style: italic
}


.forms-inputs {
  position: relative
}

.forms-inputs span {
  position: absolute;
  top: -18px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 15px
}

.forms-inputs input {
  height: 50px;
  border: 2px solid #eee
}

.forms-inputs input:focus {
  box-shadow: none;
  outline: none;
  border: 2px solid #000
}

.btn {
  height: 50px
}
</style>